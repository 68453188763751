import styled, { keyframes } from 'styled-components';

const ModalButton = ({ children, closeModal }) => {
  return <CloseBtn onClick={() => closeModal()}>{children}</CloseBtn>;
};

const WindowBtn = styled.button`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 50px;
    height: 50px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    svg {
      fill: #333;
    }
  }
`;

const slideIn = keyframes`
  0% {opacity: 0; }
  100% { opacity: 1; }
`;

const CloseBtn = styled(WindowBtn)`
  opacity: 0;
  animation: ${slideIn} 0.15s 0.05s linear forwards;

  svg {
    width: auto;
    height: auto;
    fill: grey;
    width: 15px;
    height: 15px;
  }
`;

export default ModalButton;
