import styled, { keyframes } from 'styled-components';

const ConfirmModal = ({ closeModal, closeConfirm, isOpen }) => {
  return (
    <Wrapper>
      <Container onClick={e => e.stopPropagation()}>
        <CancelWrapper>
          <Text>Are you sure you wish to exit the mint process?</Text>
          <ButtonWrapper>
            <CancelBtn onClick={() => closeConfirm()}>Cancel</CancelBtn>
            <ConfirmBtn onClick={() => closeModal()}>Exit</ConfirmBtn>
          </ButtonWrapper>
        </CancelWrapper>
      </Container>
    </Wrapper>
  );
};

const Text = styled.div`
  padding: 35px 15px 0 20px;
`;

const previewSlideIn = keyframes`
  0% { transform: translateY(4px) scale(.98); opacity: 0.4; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`;

const Container = styled.div.attrs(props => ({
  width: props.width,
  height: props.height,
}))`
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  color: #000;
  animation: ${previewSlideIn} 0.35s cubic-bezier(0.25, 1, 0.5, 1) forwards;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const CancelWrapper = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'TT Commons';
  padding: 0px 36px 32px 16px;
  font-size: 1.5rem;
  line-height: 1.65rem;
  row-gap: 35px;

  @media (max-width: 1024px) {
    width: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  column-gap: 15px;

  @media (max-width: 1024px) {
  }
`;

const Btn = styled.button`
  width: 100px;
  height: 55px;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 6px;

  @media (max-width: 1024px) {
    width: 100px;
  }
`;

const ConfirmBtn = styled(Btn)`
  background-color: #dd2222;
  color: #fff;

  &:hover {
    filter: brightness(1.05);
    background-color: #f02d2d;
  }
`;

const CancelBtn = styled(Btn)`
  color: grey;
  &:hover {
    color: #000;
  }
`;

const Wrapper = styled.div`
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

export default ConfirmModal;
