import styled, { keyframes } from 'styled-components';
import { useState, useEffect, useRef, Suspense, lazy } from 'react';
import ModalButton from 'shared/components/ModalButton';
import ConfirmModal from 'Dapp/components/ConfirmModal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'Dapp/fontStyles.css';
import WebFont from 'webfontloader';
import pawIconWhite from 'App/assets/images/general/title-icon-paw-white.png';
// import Contract from 'Project/Dapp';
const Contract = lazy(() => import('Dapp'));

const Modal = ({ closeModal, isOpen, width = 500, height = 500 }) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['TT Commons', 'Quincy CF'],
      },
    });
  }, []);

  useEffect(() => {
    // hack to fix ios scrolling to top
    const storedRequestAnimationFrame = window.requestAnimationFrame;
    window.requestAnimationFrame = () => 42;
    disableBodyScroll(modalRef.current);
    window.requestAnimationFrame = storedRequestAnimationFrame;

    if (isOpen) disableBodyScroll(modalRef.current);
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen} ref={modalRef} onClick={() => setIsPromptOpen(true)}>
      <Suspense
        fallback={
          <FloatLoading>
            <PawLoaderImg src={pawIconWhite} alt="" />
            <StackTxt>
              <LoadTxt>Loading </LoadTxt>
              <LoadTxt>Mint DAPP</LoadTxt>
            </StackTxt>
          </FloatLoading>
        }
      >
        <Container width={width} height={height} onClick={e => e.stopPropagation()}>
          <Contract setClose={closeModal} />
          <TopButtonWrapper>
            <ModalButton closeModal={() => setIsPromptOpen(true)}>
              <Svg viewBox="0 0 1000 1000">
                <g>
                  <path d="M617.2,495.8l349.1,350.9c31.7,31.8,31.7,83.5,0,115.3c-31.7,31.9-83.1,31.9-114.8,0L502.4,611.2L149.8,965.6c-32,32.2-83.8,32.2-115.8,0c-32-32.1-32-84.3,0-116.4l352.6-354.5L48.2,154.6c-31.7-31.9-31.7-83.5,0-115.4c31.7-31.9,83.1-31.9,114.7,0l338.4,340.2l343.3-345c32-32.1,83.8-32.1,115.8,0c32,32.2,32,84.3,0,116.4L617.2,495.8z" />
                </g>
              </Svg>
            </ModalButton>
          </TopButtonWrapper>
        </Container>
      </Suspense>
      {isPromptOpen && (
        <ConfirmModal
          closeModal={() => {
            setIsPromptOpen(false);
            closeModal();
          }}
          closeConfirm={() => setIsPromptOpen(false)}
        />
      )}
    </Wrapper>
  );
};

const pulseOpacityAnim = keyframes`
  0% { opacity: 0; }
  5% { opacity: 0.9; }
  50% { opacity: 0.9; }
  75% { opacity: 0; }
  100% { opacity: 0; }
`;

const StackTxt = styled.div`
  display: flex;
  opacity: 0;
  align-items: center;
  row-gap: 0.25rem;
  flex-direction: column;
  animation: ${pulseOpacityAnim} 3s 0.2s ease-in-out infinite;
`;

const LoadTxt = styled.div`
  opacity: 0;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  font-family: 'Rubik';
  color: #fff;
  animation: ${pulseOpacityAnim} 3s 0.2s ease-in-out infinite;
`;

const PawLoaderImg = styled.img`
  opacity: 0;
  width: 60px;
  height: 60px;
  image-rendering: crisp-edges;
  animation: ${pulseOpacityAnim} 2s 1s ease-in-out infinite;
`;

const previewSlideIn = keyframes`
  0% { transform: translateY(4px) scale(.98); opacity: 0.4; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`;

const FloatLoading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 200px;
`;

const Container = styled.div.attrs(props => ({
  width: props.width,
  height: props.height,
}))`
  position: relative;
  background-color: #fff;
  border-radius: 18px;
  color: #000;
  opacity: 0;
  animation: ${previewSlideIn} 0.35s cubic-bezier(0.25, 1, 0.5, 1) forwards;

  @media (max-width: 1024px) {
    background-color: #fff;
    margin: 180px 5% 40px 5%;
    border-radius: 12px;
    opacity: 1;
  }

  @media (max-width: 480px) {
    background-color: #fff;
    margin: 40px 5% 40px 5%;
  }
`;

const TopButtonWrapper = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;

  @media (max-width: 1024px) {
    top: 11px;
    right: 11px;
  }
`;

const Svg = styled.svg`
  width: 20px;
  height: 20px;
  fill: #fff;
`;

const Wrapper = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  ${props => (props.isOpen ? 'display: flex;' : 'display: none;')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.8);

  @media (max-width: 1024px) {
    /* align-items: flex-start; */
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export default Modal;
