import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body, #root {
    scrollbar-gutter: stable;
  }

  :root {
    --highlight-color: #05fcf1;
    --highlight-color-secondary: #039791;
    --highlight-border-radius: 10px;
    --highlight-border-radius-sm: 7px;
    --highlight-color-subdued: #1ac1b9;

    --filter-sort: #888;
    --filter-sort-active: #555;
    --filter-sort-hover: #999;

    --filter-link: #888;
    --filter-link-active: #555;
    --filter-link-hover: #999;

    --filter-checkbox: #888;
    --filter-checkbox-hover: #555;
    --filter-checkbox-active: #999;
  }

  body {
    color: #fff;
    /* background: #161434; */
    /* background: linear-gradient(0deg, #221e5b 1%, #221e5b 3%, #221e5b 6%, #221e5b 30%, #221e5b 50%); */
    /* background: linear-gradient(0deg, #28065d 1%, #340970 3%, #340970 6%, #340970 30%, #221e5b 50%); */
    background: hsl(244deg 44% 7%) 65%;
    font-family: 'Rubik', Arial, Helvetica, Verdana, sans-serif;
    width: 100%;



    @media (min-width: 1025px) {
      scrollbar-gutter: stable;
    }
  }

  img {
    height: fit-content;
  }

  em {
    text-decoration: italic;
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  details > summary {
  list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
  }

  *, *:after, *:before, input[type="search"] {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    font-weight: inherit;
	  font-style: inherit;
    font-size: 100%;
	  font-family: inherit;
	  vertical-align: baseline;
  }

  button {
    background: none;
    border: none;
  }

  /* Workaround for IE11 focus highlighting for select elements */
  select::-ms-value {
    background: none;
    color: #42413d;
  }

  [role="button"], button, input, select, textarea {
    outline: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 1;
    }
  }

  [role="button"], button, input, textarea {
    appearance: none;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  
  select::-ms-expand {
    display: none;
  }

  select option {
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
    line-height: 1.4285;

    @media (max-width: 1024px) {
    margin-top: 0;

      margin-bottom: 18px;
    }
  }

  textarea {
    line-height: 1.4285;
  }

  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    touch-action: manipulation;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
