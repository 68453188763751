import { createSlice } from '@reduxjs/toolkit';

const initialFilterState = {
  Background: [],
  Tail: [],
  Body: [],
  Clothes: [],
  Mouth: [],
  Head: [],
  Eyes: [],
  OrderAdded: [],
  count: {
    Background: 0,
    Tail: 0,
    Body: 0,
    Clothes: 0,
    Mouth: 0,
    Head: 0,
    Eyes: 0,
  },
};

// const reducer = (state, action) => {
//   const { traitType, value } = action.payload;
//   let updatedState = state;

//   switch (action.type) {
//     case 'ADD':
//       updatedState[traitType] = [...state[traitType], value];
//       return { ...updatedState };
//     case 'REMOVE':
//       updatedState[traitType] = state[traitType].filter(item => item !== value);
//       return { ...updatedState };
//     default:
//       throw new Error();
//   }
// };

const updateCount = (state, type, isAdding = false) => {
  let count = 0;

  for (let index = 0; index < state[type].length; index++) {
    count++;
  }

  return count;
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState: initialFilterState,
  reducers: {
    add: (state, action) => {
      state[action.payload.traitType].push(action.payload.value);
      let newCount = updateCount(state, action.payload.traitType, true);
      state['count'][action.payload.traitType] = newCount;
      state['OrderAdded'].push({ traitType: action.payload.traitType, value: action.payload.value });
    },
    remove: (state, action) => {
      state[action.payload.traitType] = state[action.payload.traitType].filter(traitType => traitType !== action.payload.value);
      let newCount = updateCount(state, action.payload.traitType);
      state['count'][action.payload.traitType] = newCount;
      state['OrderAdded'] = state['OrderAdded'].filter(trait => {
        let isTrue = trait.value !== action.payload.value && trait.traitType !== action.payload.value;
        return isTrue;
      });
    },
    removeAll: state => {
      return initialFilterState;
    },
  },
});

export const { add, remove, removeAll } = filterSlice.actions;

export const selectFilters = state => state.filters;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

// export const reducerSlice = createSlice({
//   name: 'counter',
//   initialState: {
//     value: 0,
//   },
//   reducers: {
//     increment: state => {
//       // Redux Toolkit allows us to write "mutating" logic in reducers. It
//       // doesn't actually mutate the state because it uses the immer library,
//       // which detects changes to a "draft state" and produces a brand new
//       // immutable state based off those changes
//       state.value += 1;
//     },
//     decrement: state => {
//       state.value -= 1;
//     },
//     incrementByAmount: (state, action) => {
//       state.value += action.payload;
//     },
//   },
// });

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export default filterSlice.reducer;
