import { useState, useRef } from 'react';

import styled, { keyframes } from 'styled-components';

const Tooltip = ({ children, content, width = 'auto', delay = false, darkMode = true, delayTime = 400, offset = -38 }) => {
  const [isOpen, setIsOpen] = useState(false);
  let timeoutRef = useRef();

  let enter = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(true);
    }, delayTime);
  };

  let leave = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(false);
  };

  return (
    <Wrapper onMouseEnter={() => enter()} onMouseLeave={() => leave()}>
      {children}
      <Window offset={offset} darkMode={darkMode} width={width} onMouseEnter={e => e.stopPropagation()} isVisible={isOpen}>
        {content}
      </Window>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const fadeInAnim = keyframes`
  0% { opacity: 0; transform: translateY(-10px) scale(0.95); }
  100% { opacity: 1; transform: translateY(0) scale(1);  }
`;

const fadeOutAnim = keyframes`
  0% { opacity: 0; transform: translateY(0) scale(1); }
  100% { opacity: 0; transform: translateY(0) scale(1); }
`;

const Window = styled.div.attrs(props => ({
  width: `${props.width}px` || 'auto',
}))`
  position: absolute;
  font-size: 1rem;

  top: 130%;
  left: ${props => props.offset}px;
  transform: translateX(-50%);
  line-height: 1.35rem;

  /* width: 200px; */
  max-width: 250px;
  padding: 20px;
  width: ${props => props.width};
  ${props =>
    props.darkMode ? 'background-color: rgba(9, 9, 9, 0.9);color: #fff;' : 'background-color: rgba(255, 255, 255, 0.97);color: #000;'}

  border-radius: 8px;
  z-index: 5000;
  text-align: center;
  line-height: 1.3rem;
  /* transition: opacity 0.15s; */
  pointer-events: none;
  animation: ${props => (props.isVisible ? fadeInAnim : fadeOutAnim)} 0.07s linear forwards;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: auto;
    border-color: transparent;
    border-top-color: #d00;
    border-style: solid;
    border-width: 8px 0.31em 0;
    height: 0;
    line-height: 0;
    top: -13px;
    left: 50%;
    transform: rotate(90deg);
    border-width: 0.61em 7px 0.61em 0;
    border-top-color: transparent;
    ${props =>
      props.darkMode
        ? 'border-right-color: rgba(9, 9, 9, 0.9);'
        : 'border-right-color: rgba(255, 255, 255, 0.97);'}/* transition: all 0.15s linear; */
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export default Tooltip;
