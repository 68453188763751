export const equipmentFilters = {
  Background: { name: '', rarity: 1 },
  Tail: { name: '', rarity: 1 },
  Body: { name: '', rarity: 1 },
  Clothes: { name: '', rarity: 1 },
  Mouth: { name: '', rarity: 1 },
  Eyes: { name: '', rarity: 1 },
  Head: { name: '', rarity: 1 },
  Foil: { name: '', rarity: 1 },
};

export const flatEquipmentList = {
  Common: 1,
  Uncommon: 2,
  Pristine: 3,
  Rare: 4,
  Epic: 5,
  Legendary: 6,
  Artifact: 7,
  Heirloom: 8,
  Foil: 9,
  None: 1,
  Curly: 2,
  Teal: 1,
  Brown: 2,
  Pink: 2,
  Charcoal: 3,
  Yellow: 3,
  Iridescent: 4,
  Red: 4,
  Unibright: 5,
  RGB: 5,
  'Tanktop Black Stripes': 1,
  'Red T-Shirt': 1,
  'Red Sweater': 1,
  'Black T-Shirt': 1,
  'Dress Shirt Black Tie': 1,
  None: 2,
  Backpack: 2,
  'Dress Shirt Backpack': 2,
  'Shark Movie T-Shirt': 2,
  'Endless Seasons Tanktop': 2,
  'Gold Basketball Jersey': 2,
  'Green Basketball Jersey': 2,
  'Charcoal Hoodie': 2,
  'Tanktop Sports': 2,
  'Dress Shirt Casual Jacket': 3,
  'Baseledger Sweater': 3,
  'Orange Fishing Vest': 3,
  'Green Military Jacket': 3,
  'Brown Sherpa Jacket': 3,
  'Orange Puffer Jacket': 3,
  'Black Bomber Jacket': 3,
  'Blue Hawaiian Shirt': 3,
  'Brown Blazer': 3,
  'Marlin Baseball Jersey': 3,
  'Ethereum Sweater': 4,
  'Black Robe': 4,
  'Black Hawaiian Shirt': 4,
  'Black Puffer Jacket': 4,
  'Baseline Hoodie': 4,
  'Unibright T-Shirt': 4,
  'Red Biker Jacket': 5,
  'Black Spiked Biker Jacket': 5,
  'Red Boxing Gloves': 5,
  "King's Robe": 5,
  'Astronaut Jumpsuit': 5,
  Happy: 1,
  Straight: 1,
  Smirk: 1,
  Subdued: 1,
  'Brown Beard': 2,
  'Red Beard': 2,
  Surprised: 2,
  Tongue: 2,
  Shocked: 3,
  Aggressive: 3,
  Bubblegum: 4,
  'Red Moustache': 4,
  'Brown Moustache': 4,
  Neutral: 1,
  Winking: 1,
  Frustrated: 1,
  Relieved: 1,
  Tattoo: 5,
  Ecstatic: 1,
  Surprised: 1,
  Indifferent: 1,
  Angry: 1,
  Shifty: 1,
  Shocked: 1,
  Confused: 1,
  'Blue Aviator Sunglasses': 3,
  'Reading Glasses': 3,
  Dead: 3,
  'Eye Patch': 3,
  'White 3D Glasses': 4,
  'Black 3D Glasses': 4,
  'Black Sunglasses': 2,
  'Narrow Reading Glasses': 2,
  'Laser Eyes': 4,
  None: 1,
  'Teal Mohawk': 1,
  'Yellow Mohawk': 1,
  'Brown Trucker Hat': 1,
  'Red Beanie': 2,
  'Backwards Blue Baseball Cap': 2,
  'Blue Baseball Jersey': 3,
  'Black Bowler Hat': 2,
  'Brown Bowler Hat': 2,
  Bandages: 2,
  'Green Laurel Wreath': 2,
  'Fly Fishing Hat': 2,
  "Prince's Crown": 2,
  'Survivor Bandana': 2,
  'Blue Beanie': 3,
  'Black Bowler Hat': 3,
  'Baseline Bucket Hat': 3,
  'Ethereum Baseball Cap': 3,
  'Gold Laurel Wreath': 3,
  'Arrow Pierced': 3,
  'Unibright Baseball Cap': 3,
  Headphones: 3,
  'Raggedy Green Baseball Cap': 4,
  'Harlem Sweatband': 4,
  'Rainbow Mohawk': 4,
  Halo: 4,
  'VR Headset': 4,
  "King's Crown": 4,
  'Astronaut Helmet': 4,
};

export const equipmentTierList = {
  Background: {
    Common: 0,
    Uncommon: 1,
    Pristine: 2,
    Rare: 3,
    Epic: 4,
    Legendary: 5,
    Artifact: 6,
    Heirloom: 7,
    Foil: 8,
  },
  Tail: {
    None: 0,
    Curly: 1,
  },
  Body: {
    Teal: 0,
    Brown: 1,
    Pink: 1,
    Charcoal: 2,
    Yellow: 2,
    Iridescent: 3,
    Red: 3,
    Unibright: 4,
    RGB: 4,
    Tattoo: 4,
  },
  Clothes: {},
  Mouth: {},
  Eyes: {},
  Head: {},
};

export const equipmentList = {
  Background: [
    [{ name: 'Common', rarity: 1, count: 0 }],
    [{ name: 'Uncommon', rarity: 2, count: 0 }],
    [{ name: 'Pristine', rarity: 3, count: 0 }],
    [{ name: 'Rare', rarity: 4, count: 0 }],
    [{ name: 'Epic', rarity: 5, count: 0 }],
    [{ name: 'Legendary', rarity: 6, count: 0 }],
    [{ name: 'Artifact', rarity: 7, count: 0 }],
    [{ name: 'Heirloom', rarity: 8, count: 0 }],
    [{ name: 'Foil', rarity: 8, count: 0 }],
  ],
  Tail: [[{ name: 'None', rarity: 1, count: 0 }], [{ name: 'Curly', rarity: 2, count: 0 }]],
  Body: [
    [{ name: 'Teal', rarity: 1, count: 0 }],
    [
      { name: 'Brown', rarity: 2, count: 0 },
      { name: 'Pink', rarity: 2, count: 0 },
    ],
    [
      { name: 'Charcoal', rarity: 3, count: 0 },
      { name: 'Yellow', rarity: 3, count: 0 },
    ],
    [
      { name: 'Iridescent', rarity: 4, count: 0 },
      { name: 'Red', rarity: 4, count: 0 },
    ],
    [
      { name: 'Unibright', rarity: 5, count: 0 },
      { name: 'RGB', rarity: 5, count: 0 },
      { name: 'Tattoo', rarity: 5, count: 0 },
    ],
  ],
  Clothes: [
    [
      { name: 'Tanktop Black Stripes', rarity: 1, count: 0 },
      { name: 'Red T-Shirt', rarity: 1, count: 0 },
      { name: 'Red Sweater', rarity: 1, count: 0 },
      { name: 'Black T-Shirt', rarity: 1, count: 0 },
      { name: 'Dress Shirt Black Tie', rarity: 1, count: 0 },
    ],
    [
      { name: 'None', rarity: 2, count: 0 },
      { name: 'Backpack', rarity: 2, count: 0 },
      { name: 'Dress Shirt Backpack', rarity: 2, count: 0 },
      { name: 'Shark Movie T-Shirt', rarity: 2, count: 0 },
      { name: 'Endless Seasons Tanktop', rarity: 2, count: 0 },
      { name: 'Gold Basketball Jersey', rarity: 2, count: 0 },
      { name: 'Green Basketball Jersey', rarity: 2, count: 0 },
      { name: 'Charcoal Hoodie', rarity: 2, count: 0 },
      { name: 'Tanktop Sports', rarity: 2, count: 0 },
    ],
    [
      { name: 'Dress Shirt Casual Jacket', rarity: 3, count: 0 },
      { name: 'Baseledger Sweater', rarity: 3, count: 0 },
      { name: 'Orange Fishing Vest', rarity: 3, count: 0 },
      { name: 'Green Military Jacket', rarity: 3, count: 0 },
      { name: 'Brown Sherpa Jacket', rarity: 3, count: 0 },
      { name: 'Orange Puffer Jacket', rarity: 3, count: 0 },
      { name: 'Black Bomber Jacket', rarity: 3, count: 0 },
      { name: 'Blue Hawaiian Shirt', rarity: 3, count: 0 },
      { name: 'Brown Blazer', rarity: 3, count: 0 },
      { name: 'Blue Baseball Jersey', rarity: 3, count: 0 },
      { name: 'Marlin Baseball Jersey', rarity: 3, count: 0 },
    ],
    [
      { name: 'Ethereum Sweater', rarity: 4, count: 0 },
      { name: 'Black Robe', rarity: 4, count: 0 },
      { name: 'Black Hawaiian Shirt', rarity: 4, count: 0 },
      { name: 'Black Puffer Jacket', rarity: 4, count: 0 },
      { name: 'Baseline Hoodie', rarity: 4, count: 0 },
      { name: 'Unibright T-Shirt', rarity: 4, count: 0 },
    ],
    [
      { name: 'Red Biker Jacket', rarity: 5, count: 0 },
      { name: 'Black Spiked Biker Jacket', rarity: 5, count: 0 },
      { name: 'Red Boxing Gloves', rarity: 5, count: 0 },
      { name: "King's Robe", rarity: 5, count: 0 },
      { name: 'Astronaut Jumpsuit', rarity: 5, count: 0 },
    ],
  ],
  Mouth: [
    [
      { name: 'Happy', rarity: 1, count: 0 },
      { name: 'Straight', rarity: 1, count: 0 },
      { name: 'Smirk', rarity: 1, count: 0 },
      { name: 'Subdued', rarity: 1, count: 0 },
    ],
    [
      { name: 'Brown Beard', rarity: 2, count: 0 },
      { name: 'Red Beard', rarity: 2, count: 0 },
      { name: 'Surprised', rarity: 2, count: 0 },
      { name: 'Tongue', rarity: 2, count: 0 },
    ],
    [
      { name: 'Shocked', rarity: 3, count: 0 },
      { name: 'Aggressive', rarity: 3, count: 0 },
    ],
    [
      { name: 'Bubblegum', rarity: 4, count: 0 },
      { name: 'Red Moustache', rarity: 4, count: 0 },
      { name: 'Brown Moustache', rarity: 4, count: 0 },
    ],
  ],
  Eyes: [
    [
      { name: 'Neutral', rarity: 1, count: 0 },
      { name: 'Winking', rarity: 1, count: 0 },
      { name: 'Frustrated', rarity: 1, count: 0 },
      { name: 'Relieved', rarity: 1, count: 0 },
      { name: 'Ecstatic', rarity: 1, count: 0 },
      { name: 'Surprised', rarity: 1, count: 0 },
      { name: 'Indifferent', rarity: 1, count: 0 },
      { name: 'Angry', rarity: 1, count: 0 },
      { name: 'Shifty', rarity: 1, count: 0 },
      { name: 'Shocked', rarity: 1, count: 0 },
      { name: 'Confused', rarity: 1, count: 0 },
    ],
    [
      { name: 'Black Sunglasses', rarity: 2, count: 0 },
      { name: 'Narrow Reading Glasses', rarity: 2, count: 0 },
    ],
    [
      { name: 'Dead', rarity: 3, count: 0 },
      { name: 'Blue Aviator Sunglasses', rarity: 3, count: 0 },
      { name: 'Reading Glasses', rarity: 3, count: 0 },
      { name: 'Eye Patch', rarity: 3, count: 0 },
    ],
    [
      { name: 'White 3D Glasses', rarity: 4, count: 0 },
      { name: 'Black 3D Glasses', rarity: 4, count: 0 },
      { name: 'Laser Eyes', rarity: 4, count: 0 },
    ],
  ],
  Head: [
    [
      { name: 'None', rarity: 1, count: 0 },
      { name: 'Teal Mohawk', rarity: 1, count: 0 },
      { name: 'Yellow Mohawk', rarity: 1, count: 0 },
      { name: 'Brown Trucker Hat', rarity: 1, count: 0 },
    ],
    [
      { name: 'Red Beanie', rarity: 2, count: 0 },
      { name: 'Backwards Blue Baseball Cap', rarity: 2, count: 0 },
      { name: 'Black Bowler Hat', rarity: 2, count: 0 },
      { name: 'Brown Bowler Hat', rarity: 2, count: 0 },
      { name: 'Bandages', rarity: 2, count: 0 },
      { name: 'Green Laurel Wreath', rarity: 2, count: 0 },
      { name: 'Fly Fishing Hat', rarity: 2, count: 0 },
      { name: "Prince's Crown", rarity: 2, count: 0 },
      { name: 'Survivor Bandana', rarity: 2, count: 0 },
    ],
    [
      { name: 'Blue Beanie', rarity: 3, count: 0 },
      { name: 'Black Bowler Hat', rarity: 3, count: 0 },
      { name: 'Baseline Bucket Hat', rarity: 3, count: 0 },
      { name: 'Ethereum Baseball Cap', rarity: 3, count: 0 },
      { name: 'Gold Laurel Wreath', rarity: 3, count: 0 },
      { name: 'Arrow Pierced', rarity: 3, count: 0 },
      { name: 'Unibright Baseball Cap', rarity: 3, count: 0 },
      { name: 'Headphones', rarity: 3, count: 0 },
    ],
    [
      { name: 'Raggedy Green Baseball Cap', rarity: 4, count: 0 },
      { name: 'Harlem Sweatband', rarity: 4, count: 0 },
      { name: 'Rainbow Mohawk', rarity: 4, count: 0 },
      { name: 'Halo', rarity: 4, count: 0 },
      { name: 'VR Headset', rarity: 4, count: 0 },
      { name: "King's Crown", rarity: 4, count: 0 },
      { name: 'Astronaut Helmet', rarity: 4, count: 0 },
    ],
  ],
};

export const itemsFlat = {
  Background: [
    { name: 'Common', rarity: 1, count: 0 },
    { name: 'Uncommon', rarity: 2, count: 0 },
    { name: 'Pristine', rarity: 3, count: 0 },
    { name: 'Rare', rarity: 4, count: 0 },
    { name: 'Epic', rarity: 5, count: 0 },
    { name: 'Legendary', rarity: 6, count: 0 },
    { name: 'Artifact', rarity: 7, count: 0 },
    { name: 'Heirloom', rarity: 8, count: 0 },
    { name: 'Foil', rarity: 9, count: 0 },
  ],
  Tail: [
    { name: 'None', rarity: 1, count: 0 },
    { name: 'Curly', rarity: 2, count: 0 },
  ],
  Body: [
    { name: 'Teal', rarity: 1, count: 0 },
    { name: 'Brown', rarity: 2, count: 0 },
    { name: 'Pink', rarity: 2, count: 0 },
    { name: 'Charcoal', rarity: 3, count: 0 },
    { name: 'Yellow', rarity: 3, count: 0 },
    { name: 'Iridescent', rarity: 4, count: 0 },
    { name: 'Red', rarity: 4, count: 0 },
    { name: 'Unibright', rarity: 5, count: 0 },
    { name: 'RGB', rarity: 5, count: 0 },
    { name: 'Tattoo', rarity: 5, count: 0 },
  ],
  Clothes: [
    { name: 'Tanktop Black Stripes', rarity: 1, count: 0 },
    { name: 'Red T-Shirt', rarity: 1, count: 0 },
    { name: 'Red Sweater', rarity: 1, count: 0 },
    { name: 'Black T-Shirt', rarity: 1, count: 0 },
    { name: 'Dress Shirt Black Tie', rarity: 1, count: 0 },
    { name: 'None', rarity: 2, count: 0 },
    { name: 'Backpack', rarity: 2, count: 0 },
    { name: 'Dress Shirt Backpack', rarity: 2, count: 0 },
    { name: 'Shark Movie T-Shirt', rarity: 2, count: 0 },
    { name: 'Endless Seasons Tanktop', rarity: 2, count: 0 },
    { name: 'Gold Basketball Jersey', rarity: 2, count: 0 },
    { name: 'Green Basketball Jersey', rarity: 2, count: 0 },
    { name: 'Charcoal Hoodie', rarity: 2, count: 0 },
    { name: 'Tanktop Sports', rarity: 2, count: 0 },
    { name: 'Dress Shirt Casual Jacket', rarity: 3, count: 0 },
    { name: 'Baseledger Sweater', rarity: 3, count: 0 },
    { name: 'Orange Fishing Vest', rarity: 3, count: 0 },
    { name: 'Green Military Jacket', rarity: 3, count: 0 },
    { name: 'Brown Sherpa Jacket', rarity: 3, count: 0 },
    { name: 'Orange Puffer Jacket', rarity: 3, count: 0 },
    { name: 'Black Bomber Jacket', rarity: 3, count: 0 },
    { name: 'Blue Hawaiian Shirt', rarity: 3, count: 0 },
    { name: 'Blue Baseball Jersey', rarity: 3, count: 0 },
    { name: 'Brown Blazer', rarity: 3, count: 0 },
    { name: 'Marlin Baseball Jersey', rarity: 3, count: 0 },
    { name: 'Ethereum Sweater', rarity: 4, count: 0 },
    { name: 'Black Robe', rarity: 4, count: 0 },
    { name: 'Black Hawaiian Shirt', rarity: 4, count: 0 },
    { name: 'Black Puffer Jacket', rarity: 4, count: 0 },
    { name: 'Baseline Hoodie', rarity: 4, count: 0 },
    { name: 'Unibright T-Shirt', rarity: 4, count: 0 },
    { name: 'Red Biker Jacket', rarity: 5, count: 0 },
    { name: 'Black Spiked Biker Jacket', rarity: 5, count: 0 },
    { name: 'Red Boxing Gloves', rarity: 5, count: 0 },
    { name: "King's Robe", rarity: 5, count: 0 },
    { name: 'Astronaut Jumpsuit', rarity: 5, count: 0 },
  ],
  Mouth: [
    { name: 'Happy', rarity: 1, count: 0 },
    { name: 'Straight', rarity: 1, count: 0 },
    { name: 'Smirk', rarity: 1, count: 0 },
    { name: 'Subdued', rarity: 1, count: 0 },
    { name: 'Brown Beard', rarity: 2, count: 0 },
    { name: 'Red Beard', rarity: 2, count: 0 },
    { name: 'Surprised', rarity: 2, count: 0 },
    { name: 'Tongue', rarity: 2, count: 0 },
    { name: 'Shocked', rarity: 3, count: 0 },
    { name: 'Aggressive', rarity: 3, count: 0 },
    { name: 'Bubblegum', rarity: 4, count: 0 },
    { name: 'Red Moustache', rarity: 4, count: 0 },
  ],
  Eyes: [
    { name: 'Neutral', rarity: 1, count: 0 },
    { name: 'Winking', rarity: 1, count: 0 },
    { name: 'Frustrated', rarity: 1, count: 0 },
    { name: 'Relieved', rarity: 1, count: 0 },
    { name: 'Ecstatic', rarity: 1, count: 0 },
    { name: 'Indifferent', rarity: 1, count: 0 },
    { name: 'Angry', rarity: 1, count: 0 },
    { name: 'Shifty', rarity: 1, count: 0 },
    { name: 'Shocked', rarity: 1, count: 0 },
    { name: 'Confused', rarity: 1, count: 0 },
    { name: 'Black Sunglasses', rarity: 2, count: 0 },
    { name: 'Narrow Reading Glasses', rarity: 2, count: 0 },
    { name: 'Dead', rarity: 3, count: 0 },
    { name: 'Blue Aviator Sunglasses', rarity: 3, count: 0 },
    { name: 'Reading Glasses', rarity: 3, count: 0 },
    { name: 'Eye Patch', rarity: 3, count: 0 },
    { name: 'White 3D Glasses', rarity: 4, count: 0 },
    { name: 'Black 3D Glasses', rarity: 4, count: 0 },
    { name: 'Laser Eyes', rarity: 4, count: 0 },
  ],
  Head: [
    { name: 'None', rarity: 1, count: 0 },
    { name: 'Teal Mohawk', rarity: 1, count: 0 },
    { name: 'Yellow Mohawk', rarity: 1, count: 0 },
    { name: 'Brown Trucker Hat', rarity: 1, count: 0 },
    { name: 'Red Beanie', rarity: 2, count: 0 },
    { name: 'Backwards Blue Baseball Cap', rarity: 2, count: 0 },
    { name: 'Brown Bowler Hat', rarity: 2, count: 0 },
    { name: 'Bandages', rarity: 2, count: 0 },
    { name: 'Green Laurel Wreath', rarity: 2, count: 0 },
    { name: 'Fly Fishing Hat', rarity: 2, count: 0 },
    { name: "Prince's Crown", rarity: 2, count: 0 },
    { name: 'Survivor Bandana', rarity: 2, count: 0 },
    { name: 'Blue Beanie', rarity: 3, count: 0 },
    { name: 'Black Bowler Hat', rarity: 3, count: 0 },
    { name: 'Baseline Bucket Hat', rarity: 3, count: 0 },
    { name: 'Ethereum Baseball Cap', rarity: 3, count: 0 },
    { name: 'Gold Laurel Wreath', rarity: 3, count: 0 },
    { name: 'Arrow Pierced', rarity: 3, count: 0 },
    { name: 'Unibright Baseball Cap', rarity: 3, count: 0 },
    { name: 'Headphones', rarity: 3, count: 0 },
    { name: 'Raggedy Green Baseball Cap', rarity: 4, count: 0 },
    { name: 'Harlem Sweatband', rarity: 4, count: 0 },
    { name: 'Rainbow Mohawk', rarity: 4, count: 0 },
    { name: 'Halo', rarity: 4, count: 0 },
    { name: 'VR Headset', rarity: 4, count: 0 },
    { name: "King's Crown", rarity: 4, count: 0 },
    { name: 'Astronaut Helmet', rarity: 4, count: 0 },
  ],
};
