import Routes from 'App/Routes';
import { Reset } from 'styled-reset';
import BaseStyles from 'App/BaseStyles';
import 'App/fontStyles.css';

const App = () => {
  return (
    <>
      <Reset />
      <BaseStyles />
      <Routes />
    </>
  );
};

export default App;
