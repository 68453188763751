import { createSlice } from '@reduxjs/toolkit';
import { equipmentList, itemsFlat } from 'shared/equipmentList';
import axios from 'axios';

// const reducer = (state, action) => {
//   const { traitType, value } = action.payload;
//   let updatedState = state;

//   switch (action.type) {
//     case 'ADD':
//       updatedState[traitType] = [...state[traitType], value];
//       return { ...updatedState };
//     case 'REMOVE':
//       updatedState[traitType] = state[traitType].filter(item => item !== value);
//       return { ...updatedState };
//     default:
//       throw new Error();
//   }
// };

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: itemsFlat,
  reducers: {
    update: (state, action) => {
      state.Body = action.payload.Body;
      state.Clothes = action.payload.Clothes;
      state.Mouth = action.payload.Mouth;
      state.Eyes = action.payload.Eyes;
      state.Head = action.payload.Head;
      state.Tail = action.payload.Tail;
      state.Background = action.payload.Background;
    },
  },
});

export const { update } = equipmentSlice.actions;

export const selectEquipment = state => state.equipment;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

// export const reducerSlice = createSlice({
//   name: 'counter',
//   initialState: {
//     value: 0,
//   },
//   reducers: {
//     increment: state => {
//       // Redux Toolkit allows us to write "mutating" logic in reducers. It
//       // doesn't actually mutate the state because it uses the immer library,
//       // which detects changes to a "draft state" and produces a brand new
//       // immutable state based off those changes
//       state.value += 1;
//     },
//     decrement: state => {
//       state.value -= 1;
//     },
//     incrementByAmount: (state, action) => {
//       state.value += action.payload;
//     },
//   },
// });

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export default equipmentSlice.reducer;
