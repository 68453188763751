export const isMintEnabled = true
export const isDevelopment = false
export const isCollectionEnabled = true
export const isAlert = false
export const alertMessage = ''
export const alertSeverity = 0

export const ROUTES = {
  home: '/',
  collection: '/collection',
}
