import { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function useBodyScrollLock(ref, isMenuOpen) {
  const [isLocked, setIsLocked] = useState(isMenuOpen);

  useEffect(() => {
    // hacky body-scroll-lock fix for iOS :(
    const storedRequestAnimationFrame = window.requestAnimationFrame;
    window.requestAnimationFrame = () => 42;
    disableBodyScroll(ref.current);
    window.requestAnimationFrame = storedRequestAnimationFrame;

    if (isLocked === true) enableBodyScroll(ref.current);
    if (isLocked === false) disableBodyScroll(ref.current);

    return () => clearAllBodyScrollLocks();
  }, [isMenuOpen, isLocked, ref]);

  return [isLocked, setIsLocked];
}

export default useBodyScrollLock;
