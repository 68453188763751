import { configureStore } from '@reduxjs/toolkit';
import filterSlice from 'redux/reducers/filtersReducer';
import equipmentSlice from 'redux/reducers/equipmentReducer';

export default configureStore({
  reducer: {
    filters: filterSlice,
    equipment: equipmentSlice,
  },
});
