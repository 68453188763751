import { Route, Routes as R, BrowserRouter, Navigate } from 'react-router-dom';
import { useState, lazy, Suspense } from 'react';
import Navigation from 'Project/Navigation';
import Modal from 'Dapp/components/Modal';
// import Landing from 'Project/Landing';
// import Collection from 'Project/Collection';
import store from 'redux/store';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';

const Collection = lazy(() => import('Project/Collection'));
const Landing = lazy(() => import('Project/Landing'));
// const Modal = lazy(() => import('Project/Dapp/Modal'));

const Routes = () => {
  const [openMint, setOpenMint] = useState(false);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Navigation setOpenMint={setOpenMint} />
        <ParallaxProvider>
          <R>
            <Route
              exact
              path="/"
              element={
                <Suspense fallback={<div></div>}>
                  <Landing setOpenMint={setOpenMint} />
                </Suspense>
              }
            />
            <Route
              exact
              path="/collection"
              element={
                <Suspense fallback={<div></div>}>
                  <Collection />
                </Suspense>
              }
            />
            <Route
              exact
              path="/collection/:id"
              element={
                <Suspense fallback={<div></div>}>
                  <Collection />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </R>
        </ParallaxProvider>
        {openMint && <Modal isOpen={openMint} closeModal={() => setOpenMint(false)} />}
      </BrowserRouter>
    </Provider>
  );
};

export default Routes;
